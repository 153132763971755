<template>
  <div class="login">
    <a :href="link">Login To See Projects</a>
  </div>
</template>

<script>
export default {
  props: ["loggedIn"],
  computed: {
    link() {
      return (
        process.env.VUE_APP_ROOT_API +
        "login?intended=" +
        process.env.VUE_APP_BMP_URL
      );
    }
  },
  watch: {
    loggedIn() {
      if (this.loggedIn) this.$router.push("/");
    }
  },
  mounted() {
    if (this.loggedIn) this.$router.push("/");
  }
};
</script>

<style lang="scss" scoped>
.login {
  text-align: center;
  margin-top: 60px;
  font-size: 38px;
}
</style>
